import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
// import { TbArrowNarrowRight } from "react-icons/tb";
import { StaticImage } from "gatsby-plugin-image";

const HomeCardWrapper = styled.div`
  width:305px;
  height:405px;
  border-radius: 2rem;
  position: relative;
  display:flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  padding-top: 1rem;
  box-shadow: 0px 9px 35px rgba(0, 0, 0, 0.25);

  .card_img__container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
    width: 90%;
  }

  .card_img{
    border-radius: 2rem;
    width: auto;
    height: auto;

  }

  .card_text__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 50%;
  }

  .card_text__container h4{
    /* margin-top: 2rem; */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 123.4%;
    /* or 31px */
    margin-bottom: 0;
    text-align: center;
    letter-spacing: -0.005em;
    color: var(--blue);

    @media only screen and (min-width: 501px) and (max-width: 900px){
      margin-bottom: 1rem;
    }

    @media only screen and (min-width: 1251px){
      font-size: 30px;
      padding: 0 1rem;
    }
  }

  .card_text__container p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 152.9%;
    text-align: center;
    letter-spacing: -0.005em;
    color: #000000;
    padding: 0 1rem;
  }

  @media only screen and (min-width: 901px){
    width: 25%;
    height: 536px;
    max-width: 400px;
  }

  @media only screen and (min-width: 501px) and (max-width: 900px){
    width: 350px;
    height: 475px;
  }
`;

const RevenueReporting = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width:100%;
  position: relative;
  padding: .5rem 0;
  padding-top: 1rem;

  .report__leftCol, .report__rightCol{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .report__text{
    margin:0;
    margin-bottom: 0.25rem;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 10px !important;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.035em;
    color: #ffffff;

    @media only screen and (min-width: 501px) and (max-width: 900px){
      font-size: 15px;
    }
  }

  .report__figure{
    margin:0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px !important;
    line-height: 22px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.035em;
    color: #ffffff;

    @media only screen and (min-width: 501px) and (max-width: 900px){
      font-size: 25px;
    }
  }

  .report__arrow{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }
`;

const MobileRevenueReporting = styled.div`
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width:100%;
  position: relative;
  margin-bottom: 3rem;

  .report__leftCol, .report__rightCol{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .report__text{
    margin:0;
    margin-bottom: 0.25rem;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.035em;
    color: #ffffff;

    @media only screen and (min-width: 501px) and (max-width: 900px){
      font-size: 15px;
    }
  }

  .report__figure{
    margin:0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.035em;
    color: #ffffff;

    @media only screen and (min-width: 501px) and (max-width: 900px){
      font-size: 25px;
    }
  }

  .report__arrow{
    position:absolute;
    bottom:0%;
    left:50%;
    transform: translateX(-50%);
  }

  @media only screen and (min-width: 901px){
    display:none;
  }
`;

const DesktopRevenueReporting = styled.div`
  background-color: var(--orange);
  width:110%;
  border-radius: 3rem;
  position:relative;
  z-index:1;
  bottom: 0;

  @media only screen and (max-width: 900px){
    display:none;
  }
`;

const HomeCard = ({ card }) => {
  const { cardBodyText, cardImage, cardTitle, priorRevText, priorRevAmount, postRevText, postRevAmount } = card;
  return (
    <>
      <HomeCardWrapper>
        <div className="card_img__container">
          <GatsbyImage alt={cardImage.title} className="card_img" image={getImage(cardImage.gatsbyImageData)}/>
        </div>
        <div className="card_text__container">
          <h4>{cardTitle}</h4>
          <p>{cardBodyText}</p>
        </div>
        
        <DesktopRevenueReporting>
          <RevenueReporting>
            <div className="report__leftCol">
              <p className="report__text" >{priorRevText}</p>
              <p className="report__figure">{priorRevAmount}</p>
            </div>
            {/* <TbArrowNarrowRight className="report__arrow"color="white" fontSize={30} /> */}
            <StaticImage alt="arrow" src="../../assets/images/arrow.png"/>
            <div className="report__rightCol">
              <p className="report__text">{postRevText}</p>
              <p className="report__figure">{postRevAmount}</p>
            </div>
          </RevenueReporting>
        </DesktopRevenueReporting>
      </HomeCardWrapper>
      <MobileRevenueReporting>
        <div className="report__leftCol">
          <p className="report__text" >{priorRevText}</p>
          <p className="report__figure">{priorRevAmount}</p>
        </div>
        {/* <TbArrowNarrowRight className="report__arrow"color="white" fontSize={30} /> */}
        <StaticImage alt="arrow" src="../../assets/images/arrow.png"/>
        <div className="report__rightCol">
          <p className="report__text">{postRevText}</p>
          <p className="report__figure">{postRevAmount}</p>
        </div>
      </MobileRevenueReporting>

    </>
    
  );
};

export default HomeCard;