import * as React from "react";
import { graphql } from "gatsby";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

//Components
import SEO from "../components/seo";
import Layout from "../layouts/index";
import HomeHero from "../components/home/homeHero";
import HomeLogoCarousel from "../components/home/homeLogoCarousel";
import HomeInfo from "../components/home/homeInfo";
import HomeRecentWork from "../components/home/homeRecentWork";
import HomeHire from "../components/home/homeHire";

const IndexPage = ({ data }) => {
  const { nodes } = data.allContentfulHomePage;
  const hero = nodes.filter(node => node.sectionTitle === "01_Home - Hero");
  const cl = nodes.filter(node => node.sectionTitle === "02_Home - Logo Carousel");
  const info = nodes.filter(node => node.sectionTitle === "03_Home - Info");
  const rw = nodes.filter(node => node.sectionTitle === "04_Home - Recent Work");
  const hire = nodes.filter(node => node.sectionTitle === "05_Home - Hire");
  return (
    <Layout>
      <SEO 
        description="FFG is a growth marketing agency focused on eCommerce, B2B, and SaaS, run with industry-leading tactics in experimentation on Paid Social, Search and more."
        title="Full Funnel Growth | Growth Marketing Agency | eCommerce, SaaS, and B2B" 
      />
      <HomeHero content={hero}/>
      <HomeLogoCarousel content={cl}/>
      <HomeInfo content={info}/>
      <HomeRecentWork content={rw}/>
      <HomeHire content={hire}/>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
query HomeQuery {
  allContentfulHomePage {
    nodes {
      sectionTitle
      imageAssets {
        title
        gatsbyImageData(
          breakpoints: [320, 375, 425, 500, 768, 900, 1024, 1250, 1440, 1920],
          quality: 100,
          placeholder: BLURRED
        )
        url
      }
      bodyTexts {
        text
        prompt {
          text
        }
      }
      orangeBackgroundRectangle{
        title
        gatsbyImageData(
          quality: 100,
          placeholder: BLURRED
        )
      }
      cardComponents {
        references {
          cardBodyText
          cardTitle
          cardImage {
            gatsbyImageData(
              quality: 100,
              placeholder: BLURRED
            )
            title
            url
          }
          priorRevText
          priorRevAmount
          postRevText
          postRevAmount
        }
      }
    }
  }
}
`;