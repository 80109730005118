import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Background from "../background";
// import bulletPointImg from "../../assets/images/home/03_info/Ellipse 47.png"

const HomeInfoWrapper = styled.div`
  max-width: 1440px;
  margin-top: 7rem;
  margin-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;
  /* add stuff here - START */
  position: relative;
  
  .loc{
    position: absolute;
    height: 490px;
    width: 150px;
    left: 0;
    top: 59%;
  }

  .roc{
    position: absolute;
    height: 203px;
    width: 85px;
    right: 0;
    bottom: 0;
  }
  
  .button__container{
    width: 100%;
  }

  .hire_button--desktop{
    @media only screen and (min-width: 1025px){
      width: 100%;
    }

    @media only screen and (max-width: 768px){
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  /* add stuff here - END */

  .homeInfo__header_container{
    display:flex;
    flex-direction: column;
    align-items: center;
    /* margin-bottom: 2rem; */

    p{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 21px;
      text-align: center;
      letter-spacing: -0.005em;
      color: var(--orange);
      text-transform: uppercase;

    }

    h2{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      /* line-height: 129.9%; */
      padding-top:0;
      padding-bottom:0;
      margin-bottom: 2rem;

      text-align: center;
      letter-spacing: -0.005em;
      color: var(--blue);
      width:100%;

      @media only screen and (min-width: 640px){
        font-size: 24px;
      }

      @media only screen and (min-width:768px){
        font-size: 36px;
        width: 70%;
        text-align: left;
      }
    
      /* @media only screen and (min-width:1025px){
        font-size: 45px;
        width:56%;
        text-align: left;
      } */

      /* @media only screen and (min-width:1251px){
        font-size: 50px;
      } */

      @media only screen and (min-width: 501px) and (max-width: 768px){
        /* font-size:50px; */
        /* padding: 0 3.1rem; */
        margin-bottom: 3rem;
      }

      /* @media only screen and (max-width: 500px){
        font-size: 24px;
      } */
    }

    @media only screen and (min-width:769px){
      align-items: flex-start;
    }
  }

  .desktop__container{
    /* padding-left: 5rem; */
    display:grid;
    grid-template-columns: 50% 1fr;
    align-items: flex-start;
    position: relative;

    @media only screen and (max-width:768px){
      display:none;
    }
  }

  .homeInfo__desktop_information_container{
    padding-top: 7rem;
  }

  .desktop_phone_image__container{
    /* display:flex;
    justify-content: center;
    align-items: center; */
    position:relative;
    top:0;
    right:0;
    width:100%;
  }

  .phone_image__container{
    display:flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 2rem;

    @media only screen and (min-width: 769px){
      display:none;
    }

    @media only screen and (min-width: 501px) and (max-width: 768px){
      margin-top: 3rem;
      margin-bottom: 3rem;
      justify-content: center;
    }
  }

  .homeInfo__information_container{
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (min-width: 769px){
      display:none;
    }

  }

  .mobile_bullet_point{
    border-radius: 50%;
  }

  .information__prompt{
    display:flex;
    flex-direction: column;
    align-items: center;

    h4{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 105.4%;
      text-align: center;
      letter-spacing: -0.005em;
      color: var(--blue);
      margin-bottom: 0;

      @media only screen and (min-width: 640px){
        font-size: 20px;
      }

      @media only screen and (min-width: 768px){
        font-size: 24px;
      }
    }

    p{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 152.9%;
      text-align: center;
      letter-spacing: -0.005em;
      color: #000000;
      margin-top: 0.5rem;
      margin-bottom: 0;

      @media only screen and (min-width: 501px) and (max-width:768px){
        padding: 0 5.3rem;
      }
    }

    .prompt__orange_line{
      position:relative;
      border-left: 1px solid var(--orange);
      height: 30px;
      width: 100%;
      left:50%;
    }
  }

  .information__prompt--desktop{
    position:relative;
    align-items: flex-start;
    padding-left: 3rem;
    padding-bottom: 3rem;

    .border__container{
      display:flex;
      flex-direction: column;
      align-items: center;
      height:100%;
      position:absolute;
      top:0;
      left:0;
    }

    .bulletPointImg{
      position: absolute;
      top:0;
      left:0;
      border-radius: 50%;
    }

    .prompt__orange_line--desktop{
      height:100%;
      width:100%;
      border-left: 1px solid var(--orange);
    }

    h4{
      width:100%;
      text-align: left;
      font-size: 24px;

    }

    p{
      text-align: left;
      font-size: 16px;

      /* @media only screen and (max-width: 1250px){
        font-size: 21px;
      }

      @media only screen and (max-width: 1024px){
        font-size: 19px;
      } */
    }
  }

  @media only screen and (max-width: 500px){
    padding: 0 1.5rem;
  }

  @media only screen and (max-width: 1440px){
    margin-left: 2rem;
    margin-right: 2rem;
  }
`;

const HomeInfo = ({ content }) => {
  const { imageAssets, bodyTexts } = content[0];
  const bulletPointImg = imageAssets.filter(image => image.title === "icon - bullet point");
  return (
    <>
      <HomeInfoWrapper>
        {/* what we do */}
        <div className="homeInfo__header_container">
          <Background/>
          <p>{bodyTexts[0].text}</p>
          <h2>{bodyTexts[1].text}</h2>
          {/* hire us button */}
          <div className="button__container">
            <Link to="/our-services"><button className='hire_button hire_button--desktop'>Learn More</button></Link>
          </div>
          
        </div>

        <div className="desktop__container">
          <Background/>
          <div className="homeInfo__desktop_information_container">
            {
              bodyTexts.map((prompt, index) => {
                const block = index < 2 ? 
                  <></> 
                  : 
                  <>
                    {index === (bodyTexts.length - 1) ? 
                      <div className="information__prompt information__prompt--desktop">
                        <h4>{prompt.prompt[0].text}</h4>
                        <p>{prompt.prompt[1].text}</p>
                        <GatsbyImage alt={bulletPointImg[0].title} className="bulletPointImg" image={getImage(bulletPointImg[0].gatsbyImageData)} />
                      </div>
                      :
                      <div className="information__prompt information__prompt--desktop">
                        <h4>{prompt.prompt[0].text}</h4>
                        <p>{prompt.prompt[1].text}</p>
                        <div className="border__container">
                          <GatsbyImage alt={bulletPointImg[0].title} image={getImage(bulletPointImg[0].gatsbyImageData)} />
                          <div className="prompt__orange_line prompt__orange_line--desktop"></div>
                        </div>
                    
                      </div>
                    }
                  </>;  
                return (
                  block
                );
              })
            }

          </div>
          <div className="desktop_phone_image__container">
            <GatsbyImage alt={imageAssets[3].title} image={getImage(imageAssets[3].gatsbyImageData)}/>
          </div>
        </div>
        {/* phone image 1 | phone image 2 */}

        <div className="phone_image__container">
          <GatsbyImage alt={imageAssets[0].title} image={getImage(imageAssets[0].gatsbyImageData)}/>
          <GatsbyImage alt={imageAssets[1].title} image={getImage(imageAssets[1].gatsbyImageData)}/>
        </div>

        <div className="homeInfo__information_container">
          <Background/>
          {
            bodyTexts.map((prompt, index) => {
              const block = index < 2 ? 
                <></> 
                : 
                <>
                  {index === (bodyTexts.length - 1) ? 
                    <div className="information__prompt">
                      <h4>{prompt.prompt[0].text}</h4>
                      <p>{prompt.prompt[1].text}</p>
                    </div>
                    :
                    <div className="information__prompt">
                      <h4>{prompt.prompt[0].text}</h4>
                      <p>{prompt.prompt[1].text}</p>
                      <div className="prompt__orange_line"></div>
                      <GatsbyImage alt={bulletPointImg[0].title} className="mobile_bullet_point" image={getImage(bulletPointImg[0].gatsbyImageData)} />
                    </div>
                  }
                </>;  
              return (
                block
              );
            })
          }
        </div>
        {/* <StaticImage alt="loc" className="loc" src="../../assets/images/home/03_info/Ellipse 62.png"/>
        <StaticImage alt="roc" className="roc" src="../../assets/images/home/03_info/Ellipse 63.png"/> */}
      </HomeInfoWrapper>
    </>
    
  );
};

export default HomeInfo;