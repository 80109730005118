import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Background from "../background";

const HomeHeroWrapper = styled.div`
  max-width: 1440px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  
  .mobile{
    display:flex;
    flex-direction: column;
    align-items: center;
    position:relative;

    .hero_image__container{
      width: 100%;
    }
    .hero__image_mobile{
      width: 100%;
    }

    @media only screen and (min-width:769px){
      display:none;
    }
  }

  .desktop{
    position: relative;
    display:grid;
    grid-template-columns: 56% 1fr;

    .hero__image_container{
      position:relative;
      width:100%;
      height:100%;
      z-index:1;
    }

    .hero__image--desktop{
      position:absolute;
      width:90%;
      height:auto;
      aspect-ratio: 607/487;
      border-radius: 3rem;
      top:2%;
      right:3%;
    }

    .button__container{
      width: 100%;
    }

    .hire_button--desktop{
      @media only screen and (min-width: 1025px){
        width: 100%;
      }
    }

    @media only screen and (max-width: 1024px){
      grid-template-columns: 65% 1fr;
    }

    @media only screen and (max-width:768px){
      display:none;
    }

    @media only screen and (max-width:1440px){
      .hero__image{
        width:95%;
        height:auto;
      }  
    }
  }

  @media only screen and (max-width: 1440px){
    margin-left: 2rem;
  }

  @media only screen and (max-width: 768px){
    margin-left: 0rem;
  }

  /* @media only screen and (min-width: 1920px){
    position: relative;
    left:50%;
    transform: translateX(-50%);
    margin-left: 0;
    margin-right: 0;
  } */

`;

const HeroRowOne = styled.div`
  width:100%;
  display:flex;
  flex-direction: column;
  align-items: center;

  /* add stuff here - START */
  position:relative;

  .loc{
    position:absolute;
    width:100px;
    height:218px;
    left:0%;
    bottom:0%;
  }

  .roc{
    position:absolute;
    width:60px;
    height:180px;
    right:0%;
    top:5%;
  }
  /* add stuff here - END */

  .heroRowOne__topText{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.005em;
    color: var(--orange);
    padding-top: 2rem;
    width: 38%;

    @media only screen and (max-width: 500px){
      width: auto;
      padding: 0rem 2rem;
      margin-top: 2rem;
    }
  }

  .heroRowOne__headerText{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 105.4%;
    text-align: center;
    letter-spacing: -0.005em;
    color: var(--blue);
    margin-top:15px;
    /* padding: 0 5.5rem; */
    margin-bottom: 1rem;

    @media only screen and (max-width: 500px){
      font-size: 18px;
      /* padding: 0 2rem; */
      margin-bottom: 3rem;
    }
  }

  @media only screen and (max-width: 500px){
    padding-left: 1rem;
    padding-right: 1rem;
  }

`;

const HeroRowTwo = styled.div`
  width:100%;
  background-color: var(--orange);
  margin-bottom: 3rem;
  display:flex;
  flex-direction: column;
  align-items: center;
  z-index: 0;

  /* add stuff here - START */
  position:relative;

  .tlgc{
    position:absolute;
    height:120px;
    width:40px;
    left:0;
    top:5%;
  }

  .mgc{
    position:absolute;
    height:36px;
    width:36px;
    right:56%;
    top:48%;
  }

  .blgc{
    position:absolute;
    height: 78px;
    width:48px;
    left:0;
    bottom:10%;
  }

  .brgc{
    position:absolute;
    height: 175px;
    width:55px;
    right:0;
    bottom:-40px;
  }

  /* add stuff here - END */

  .heroRowTwo__topText{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 152.9%;
    text-align: center;
    letter-spacing: -0.005em;
    color: #ffffff;
    padding-top: 1rem;
    width: 62%;
    margin-bottom: 2rem;

    /* @media only screen and (max-width: 500px){
      margin-bottom: 1rem;
      width: 100%;
      font-size: 24px;
      padding: 1rem 3rem 0 3rem;
    } */
  }
  
  .heroRowTwo__botText{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 152.9%;
    text-align: center;
    letter-spacing: -0.005em;
    color: #ffffff;
    padding:0 2.6rem 1rem 2.6rem;
    

    /* @media only screen and (max-width: 500px){
      font-size: 20px;
      padding:0 1.25rem 1rem 1.25rem
    } */
  }

`;

const OrangeDesktopBackground = styled.div`
  /* background-color: var(--orange); */
  position:absolute;
  top:0;
  right:0;
  width: 29%;
  height: 76%;
  z-index: 0;

  .orange_image{
    width: 100%;
  }
`;

const DesktopRowOne = styled.div`
  display:flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  padding-left: 0rem;
  padding-top: 10rem;
  
  .dro_row1{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 123%;
    letter-spacing: -0.005em;
    text-transform: uppercase;
    color: var(--orange);
  }

  .dro_row2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 105%;
    letter-spacing: -0.005em;
    color: var(--blue);

    /* @media only screen and (max-width: 1250px){
      font-size:45px;
    }

    @media only screen and (max-width: 1024px){
      font-size:40px;
    } */
  }

  .dro_row3, .dro_row4{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 152.9%;
    /* or 46px */
    letter-spacing: -0.005em;
    color: #000000;
    margin-top:0;
    margin-bottom:0;

    /* @media only screen and (max-width: 1250px){
      font-size:22px;
    }

    @media only screen and (max-width: 1024px){
      font-size:20px;
    } */
  }

  .dro_row4{
    margin-bottom: 2rem;
  }

  @media only screen and (max-width: 1024px){
    padding-left: 2rem;
  }

  @media only screen and (min-width: 1441px){
    padding-right: 5rem;
  }

`;

const HomeMobileHero = ({ content }) => {
  const { imageAssets, bodyTexts, orangeBackgroundRectangle } = content[0];
  return (
    <HomeHeroWrapper>
      {/* mobile */}
      <div className="mobile">
        <HeroRowOne>
          <Background/>
          <p className='heroRowOne__topText'>{bodyTexts[0].text}</p>
          <h1 className='heroRowOne__headerText'>{bodyTexts[1].text}</h1>
          {/* <StaticImage alt="loc" className="loc" src="../../assets/images/home/01_hero/Ellipse 36.png"/>
          <StaticImage alt="roc" className="roc" src="../../assets/images/home/01_hero/Ellipse 62.png"/> */}
        </HeroRowOne>
        <div className="hero_image__container">
          <GatsbyImage alt={imageAssets[0].title} className="hero__image_mobile" image={getImage(imageAssets[0].gatsbyImageData)}/>
        </div>
        
        <HeroRowTwo>
          <Background color={"gray"} show={"hidden"}/>
          <p className='heroRowTwo__topText'>{bodyTexts[2].text}</p>
          <p className='heroRowTwo__botText'>{bodyTexts[3].text}</p>
          {/* <StaticImage alt="tlgc" className="tlgc" src="../../assets/images/home/01_hero/Ellipse 59.png"/>
          <StaticImage alt="mgc" className="mgc" src="../../assets/images/home/01_hero/Ellipse 60.png"/>
          <StaticImage alt="blgc" className="blgc" src="../../assets/images/home/01_hero/Ellipse 61.png"/>
          <StaticImage alt="brgc" className="brgc" src="../../assets/images/home/01_hero/Ellipse 34.png"/> */}
        </HeroRowTwo>
        <Link to="/contact-us"><button className='hire_button hire_button--mobile'>Hire Us</button></Link>
      </div>
      
      {/* desktop */}
      <div className="desktop">
        <Background/>
        <DesktopRowOne>
          <h1 className='dro_row1'>{bodyTexts[0].text}</h1>
          <h4 className='dro_row2'>{bodyTexts[1].text}</h4>
          <p className='dro_row3'>{bodyTexts[2].text}</p>
          <p className='dro_row4'>{bodyTexts[3].text}</p>
          <div className="button__container">
            <Link to="/contact-us"><button className='hire_button hire_button--desktop'>Hire Us</button></Link>
          </div>
          
        </DesktopRowOne>
        <div className='hero__image_container'>
          <img alt={imageAssets[0].title} className="hero__image hero__image--desktop" src={imageAssets[0].url}/>
        </div>
        <OrangeDesktopBackground>
          <GatsbyImage alt={orangeBackgroundRectangle.title} className="orange_image" image={getImage(orangeBackgroundRectangle.gatsbyImageData)}/>
        </OrangeDesktopBackground>
      </div>   
    </HomeHeroWrapper>
  );
};

export default HomeMobileHero;